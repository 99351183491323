import './App.css';
import { Route, Routes } from 'react-router';
import Home from './Components/Home';
import Test from './Components/Test';

function App() {
    return (
        <div className='App'>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/flapflap'} element={<Test/>}/>
                {/*<Route path={'/about'} element={<About/>}/>*/}
                {/*<Route path={'/contact'} element={<Contact/>}/>*/}
                {/*<Route path={'/blog'} element={<Blog/>}/>*/}
                {/*<Route path={'/blog/:bp_link_title'} element={<BlogPost/>}/>*/}
            </Routes>
        </div>
    );
}

export default App;



















