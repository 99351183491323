/*
 * Description: Home page file containing a list of faucets and various faucet details
 * Author: Tomislav Žabčić-Matić
 */

// Imports:
import * as React from 'react';
import { Helmet } from 'react-helmet';

import './HomeDesktop.css';
import './HomeMobile.css';

import { isMobile } from '../Helpers/DeviceDetect';

import yeetyHeader from '../Resources/header.png';
import yeetyBirdsLogo from '../Resources/yeety_birds_logo.svg';

import twitterIcon from '../Resources/Icons/twitter.svg';
import instagramIcon from '../Resources/Icons/instagram.svg';
import discordIcon from '../Resources/Icons/discord.svg';
import openSeaIcon from '../Resources/Icons/opensea.svg';

import tomislav from '../Resources/Founders/tomislav.svg';
import karan from '../Resources/Founders/karan.svg';
import joe from '../Resources/Founders/joe.svg';
import shanmugam from '../Resources/Founders/shanmugam.svg';
import alberto from '../Resources/Founders/alberto.svg';

// Global Constants:

// Component:
export default class Home extends React.PureComponent {
    state = {
        bird: require('../Resources/Birds/0.svg'),
        birdIndex: 0,
        spStaging: false,
        showPopup: false,
        pageScrollTop: 0,
    }

    componentDidMount() {
        document.addEventListener('mouseleave', this.handleMouseLeave);
        this.randomizeBird();
        this.handleStayFor10();
    }

    componentWillUnmount() {
        document.removeEventListener('mouseleave', this.handleMouseLeave);
    }

    randomizeBird = () => {
        const index = Math.floor(Math.random()*2000);
        const bird = require(`../Resources/Birds/${index}.svg`);

        this.setState({
            bird: bird,
            birdIndex: index,
        }, () => {
            setTimeout(this.randomizeBird, 750);
        });
    }

    handleMouseLeave = (e) => {
        if (
            e.clientY <= 0 ||
            e.clientX <= 0 ||
            (e.clientX >= window.innerWidth ||
                e.clientY >= window.innerHeight)
        ) {
            this.setState({
                showPopup: true,
            });
        }
    }

    handleStayFor10 = () => {
        setTimeout(() => {
            this.setState({
                showPopup: true,
            });
        }, 10000);
    }

    handleScroll = (e) => {
        this.setState({
            pageScrollTop: e.target.scrollTop,
        });
    }

    render() {
        const step = (this.state.pageScrollTop + 1)%(628*0.4);
        const [lwtX, lwtY, rwtX, rwtY] = [
            100 - 100*((-Math.cos(step/40) + 1)/2),
            550 - 100*((-Math.cos(step/40) + 1)/2),
            900 + 100*((-Math.cos(step/40) + 1)/2),
            550 - 100*((-Math.cos(step/40) + 1)/2),
        ];
        const radX1 = Math.min(1000000, (1 - 3*Math.log((lwtY - 236.80736)/(550 - 236.80736)))*240);
        const radX2 = Math.min(1000000, (1 - 3.5*Math.log((rwtY - 354.5923)/(550 - 354.5923)))*250);
        const radY1 = Math.min(1000000, (1 - 3*Math.log((lwtY - 236.80736)/(550 - 236.80736)))*500);
        const radY2 = Math.min(1000000, (1 - 3*Math.log((rwtY - 354.5923)/(550 - 354.5923)))*600);

        return (<div
            className={
                `Home ${isMobile(navigator) ? 'Mobile' : 'Desktop'}`
            }
            onScroll={this.handleScroll}
        >
            {(isMobile(navigator)) ? (
                <div className={'Header'}>
                    <div className={'HeaderImage'}>
                        <img
                            src={yeetyHeader}
                            alt={'Yeety Birds'}
                        />
                    </div>
                    <div className={'HeaderSocials'}>
                        <div className={'HSInner'}>
                            <div className={'SocialIcon'}>
                                <a
                                    href={'https://twitter.com/YeetyFlock'}
                                    rel={'nofollow noreferrer'}
                                    target={'_blank'}
                                >
                                    <img
                                        src={twitterIcon}
                                        alt={'Yeety Birds Twitter'}
                                        title={'Yeety Birds Twitter - @YeetyFlock'}
                                    />
                                </a>
                            </div>
                            <div className={'SocialIcon'}>
                                <a
                                    href={'https://www.instagram.com/yeetybirds/'}
                                    rel={'nofollow noreferrer'}
                                    target={'_blank'}
                                >
                                    <img
                                        src={instagramIcon}
                                        alt={'Yeety Birds Instagram'}
                                        title={'Yeety Birds Instagram - @yeetybirds'}
                                    />
                                </a>
                            </div>
                            <div className={'SocialIcon'}>
                                <a
                                    href={'https://discord.gg/RkPG55KsMX'}
                                    rel={'nofollow noreferrer'}
                                    target={'_blank'}
                                >
                                    <img
                                        src={discordIcon}
                                        alt={'Coming Soon! - Yeety Birds Discord'}
                                        title={'Discord coming soon!'}
                                    />
                                </a>
                            </div>
                            <div className={'SocialIcon'}>
                                <img
                                    src={openSeaIcon}
                                    alt={'Coming Soon! - Yeety Birds on OpenSea'}
                                    title={'Coming soon to OpenSea!'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={'Header'}>
                    <div className={'HeaderImage'}>
                        <img
                            src={yeetyHeader}
                            alt={'Yeety Birds'}
                        />
                    </div>
                    <div className={'HeaderRight'}>
                        <div className={'SocialIcon'}>
                            <a
                                href={'https://twitter.com/YeetyFlock'}
                                rel={'nofollow noreferrer'}
                                target={'_blank'}
                            >
                                <img
                                    src={twitterIcon}
                                    alt={'Yeety Birds Twitter'}
                                    title={'Yeety Birds Twitter - @YeetyFlock'}
                                />
                            </a>
                        </div>
                        <div className={'SocialIcon'}>
                            <a
                                href={'https://www.instagram.com/yeetybirds/'}
                                rel={'nofollow noreferrer'}
                                target={'_blank'}
                            >
                                <img
                                    src={instagramIcon}
                                    alt={'Yeety Birds Instagram'}
                                    title={'Yeety Birds Instagram - @yeetybirds'}
                                />
                            </a>
                        </div>
                        <div className={'SocialIcon'}>
                            <a
                                href={'https://discord.gg/RkPG55KsMX'}
                                rel={'nofollow noreferrer'}
                                target={'_blank'}
                            >
                                <img
                                    src={discordIcon}
                                    alt={'Coming Soon! - Yeety Birds Discord'}
                                    title={'Discord coming soon!'}
                                />
                            </a>
                        </div>
                        <div className={'SocialIcon'}>
                            <img
                                src={openSeaIcon}
                                alt={'Coming Soon! - Yeety Birds on OpenSea'}
                                title={'Coming soon to OpenSea!'}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className={'MainBody'}>
                <div className={'TopSection'}>
                    <div className={'GIFContainer'}>
                        <img
                            src={this.state.bird}
                            alt={'Yeety Birds GIF'}
                        />
                        <div className={'Disclaimer'}>
                            {
                                'Disclaimer: The art pictured here contains ' +
                                'randomly generated Yeety Birds that have been ' +
                                'generated using the same randomizer that will be used ' +
                                'for the official release. Features depicted here are ' +
                                'accurate but the exact combinations for the official ' +
                                'mint will be generated anew, so the exact birds ' +
                                'pictured here are not guaranteed to be in ' +
                                'the official release.'
                            }
                        </div>
                    </div>
                    <div className={'TSText'}>
                        <div className={'TSTitle'}>
                            {
                                'Here we will explore true facts about ' +
                                'the glorious Yeety Bird'
                            }
                        </div>
                        <div className={'TSParagraph'}>
                            {
                                'Yeety Birds is a collection of 10,000 unique, ' +
                                'randomly-generated collectible birds that say YEET, ' +
                                'stored on the Ethereum blockchain.'
                            }
                        </div>
                        <div className={'TSParagraph'}>
                            {
                                'Each Yeety Bird is unique and is generated from a ' +
                                'combination of 200+ possible individually coded SVG ' +
                                'traits. They have come from a distant realm to ' +
                                'finally settle in the metaverse, feeding only ' +
                                'on 100% concentrated power of YEETs.'
                            }
                        </div>
                        <div className={'TSParagraph'}>
                            {
                                'While the birds are all equally special and unique, ' +
                                'some have been infused with additional Yeety essence. ' +
                                'This can manifest in the form of rare abilities, drip, ' +
                                'and much more.'
                            }
                        </div>
                    </div>
                </div>
                <div className={'Roadmap'}>
                    <div className={'SectionHeading'}>
                        Roadmap
                    </div>
                    <div className={'SectionSubheading'}>
                        {
                            'Fvck it, no "roadmap". Don\'t trust *us*, ' +
                            'just watch what we build and let that ' +
                            'speak for itself! We\'ll deliver value ' +
                            'and utility and you can watch it getting ' +
                            'built in real time. Leaks and easter eggs AF.'
                        }
                    </div>
                    <div className={'SectionSubheading'}>
                        {
                            'With that said, we do still have a plan and ' +
                            'a vision - check it out down below!'
                        }
                    </div>
                </div>
                <div className={'Details'}>
                    <div className={'SectionHeading'}>
                        Details & Vision
                    </div>
                    <div className={'DTRow Current'}>
                        <div className={'Percentage'}>
                            {/*<div className={'PInner'}>*/}
                            {/*    {'0%'}*/}
                            {/*</div>*/}
                            <svg
                                version="1.1"
                                baseProfile="full"
                                width={`${window.innerWidth*0.18}`}
                                height={`${window.innerWidth*0.18}`}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform={
                                        `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`
                                    }
                                >
                                    <path
                                        d={
                                            `
                                            M ${lwtX} ${lwtY}
                                            A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                            A 240 500 0 0 1 500 400
                                            A 250 500 0 0 1 677.38 236.80736
                                            A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}
                                            A ${radX2} ${radY2} 0 0 0 715.3903 354.5923
                                            A 250 600 0 0 0 500 650
                                            A 250 600 0 0 0 284.6097 354.5923
                                            A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                            `
                                        }
                                        fill="none"
                                        stroke="gold"
                                        strokeWidth="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div className={'DTRContent'}>
                            <div className={'DTRTitle'}>
                                {'Phase 1 - Launch (we are here)'}
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'Site launch, socials, marketing, partnerships - ' +
                                    'in this phase, we build up our community ' +
                                    '(The Yeety Flock) so that we can yeet ' +
                                    'ourselves to the next level.'
                                }
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'Once we\'ve grown the Yeety Flock enough, we will ' +
                                    'announce mint details (mint price, mint date, ' +
                                    'and presale details), as well as the designs of a ' +
                                    'small set of custom 1-of-1 Legendary Rarity ' +
                                    'Yeety Birds. Lowkey leaks + easter eggs incoming...'
                                }
                            </div>
                        </div>
                    </div>
                    {/*<div className={'DTRow'}>*/}
                    {/*    <div className={'Percentage'}>*/}
                    {/*        /!*<div className={'PInner'}>*!/*/}
                    {/*        /!*    {'15%'}*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <svg*/}
                    {/*            version="1.1"*/}
                    {/*            baseProfile="full"*/}
                    {/*            width={`${window.innerWidth*0.18}`}*/}
                    {/*            height={`${window.innerWidth*0.18}`}*/}
                    {/*            xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        >*/}
                    {/*            <g*/}
                    {/*                transform={*/}
                    {/*                    `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                <path*/}
                    {/*                    d={*/}
                    {/*                        `*/}
                    {/*                        M ${lwtX} ${lwtY}*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 322.62 236.80736*/}
                    {/*                        A 240 500 0 0 1 500 400*/}
                    {/*                        A 250 500 0 0 1 677.38 236.80736*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 715.3903 354.5923*/}
                    {/*                        A 250 600 0 0 0 500 650*/}
                    {/*                        A 250 600 0 0 0 284.6097 354.5923*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}*/}
                    {/*                        `*/}
                    {/*                    }*/}
                    {/*                    fill="none"*/}
                    {/*                    stroke="silver"*/}
                    {/*                    strokeWidth="10"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*            </g>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className={'DTRContent'}>*/}
                    {/*        <div className={'DTRTitle'}>*/}
                    {/*            {'Phase 2 - Info-Dump'}*/}
                    {/*        </div>*/}
                    {/*        <div className={'DTRDesc'}>*/}
                    {/*            {*/}
                    {/*                'Once we\'ve grown the Yeety Flock enough, we will ' +*/}
                    {/*                'announce mint details (mint price, mint date, ' +*/}
                    {/*                'and presale details), as well as the designs of a ' +*/}
                    {/*                'small set of custom 1-of-1 Legendary Rarity ' +*/}
                    {/*                'Yeety Birds. Lowkey leaks + easter eggs incoming...'*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'DTRow'}>
                        <div className={'Percentage'}>
                            {/*<div className={'PInner'}>*/}
                            {/*    {'30%'}*/}
                            {/*</div>*/}
                            <svg
                                version="1.1"
                                baseProfile="full"
                                width={`${window.innerWidth*0.18}`}
                                height={`${window.innerWidth*0.18}`}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform={
                                        `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`
                                    }
                                >
                                    <path
                                        d={
                                            `
                                            M ${lwtX} ${lwtY}
                                            A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                            A 240 500 0 0 1 500 400
                                            A 250 500 0 0 1 677.38 236.80736
                                            A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}
                                            A ${radX2} ${radY2} 0 0 0 715.3903 354.5923
                                            A 250 600 0 0 0 500 650
                                            A 250 600 0 0 0 284.6097 354.5923
                                            A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                            `
                                        }
                                        fill="none"
                                        stroke="silver"
                                        strokeWidth="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div className={'DTRContent'}>
                            {/*<div className={'DTRTitle'}>*/}
                            {/*    {'Phase 3 - The Yeetening'}*/}
                            {/*</div>*/}
                            <div className={'DTRTitle'}>
                                {'Phase 2 - The Yeetening'}
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'On minting day, members of the Yeety Flock ' +
                                    'will be able to mint their unique Yeety Birds. ' +
                                    '10% of minting proceeds will be donated to one ' +
                                    'of several nature conservation charities, which ' +
                                    'will be presented to those who mint birds. Each ' +
                                    'minted bird equals one vote for the charity of ' +
                                    'your choice! (Choices will be published ' +
                                    'prior to mint day).'
                                }
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'Litepaper incoming! Details about features ' +
                                    'available at launch will be added here as ' +
                                    'well - stay tuned!'
                                }
                            </div>
                        </div>
                    </div>
                    {/*<div className={'DTRow'}>*/}
                    {/*    <div className={'Percentage'}>*/}
                    {/*        /!*<div className={'PInner'}>*!/*/}
                    {/*        /!*    {'40%'}*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <svg*/}
                    {/*            version="1.1"*/}
                    {/*            baseProfile="full"*/}
                    {/*            width={`${window.innerWidth*0.18}`}
                                height={`${window.innerWidth*0.18}`}*/}
                    {/*            xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        >*/}
                    {/*            <g*/}
                    {/*                transform={*/}
                    {/*                    `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                <path*/}
                    {/*                    d={*/}
                    {/*                        `*/}
                    {/*                        M ${lwtX} ${lwtY}*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 322.62 236.80736*/}
                    {/*                        A 240 500 0 0 1 500 400*/}
                    {/*                        A 250 500 0 0 1 677.38 236.80736*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 715.3903 354.5923*/}
                    {/*                        A 250 600 0 0 0 500 650*/}
                    {/*                        A 250 600 0 0 0 284.6097 354.5923*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}*/}
                    {/*                        `*/}
                    {/*                    }*/}
                    {/*                    fill="none"*/}
                    {/*                    stroke="silver"*/}
                    {/*                    strokeWidth="10"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*            </g>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className={'DTRContent'}>*/}
                    {/*        <div className={'DTRTitle'}>*/}
                    {/*            {'Phase 4 - What Awaits'}*/}
                    {/*        </div>*/}
                    {/*        <div className={'DTRDesc'}>*/}
                    {/*            {*/}
                    {/*                'We will release the whitepaper for a P2E ' +*/}
                    {/*                'game that will be open to Yeety Bird holders. ' +*/}
                    {/*                'This whitepaper will include details about game ' +*/}
                    {/*                'mechanics and tokenomics. Oh, and also breeding... ' +*/}
                    {/*                'Prepare for baby Yeetys!'*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'DTRow'}>
                        <div className={'Percentage'}>
                            {/*<div className={'PInner'}>*/}
                            {/*    {'55%'}*/}
                            {/*</div>*/}
                            <svg
                                version="1.1"
                                baseProfile="full"
                                width={`${window.innerWidth*0.18}`}
                                height={`${window.innerWidth*0.18}`}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform={
                                        `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`
                                    }
                                >
                                    <path
                                        d={
                                            `
                                            M ${lwtX} ${lwtY}
                                            A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                            A 240 500 0 0 1 500 400
                                            A 250 500 0 0 1 677.38 236.80736
                                            A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}
                                            A ${radX2} ${radY2} 0 0 0 715.3903 354.5923
                                            A 250 600 0 0 0 500 650
                                            A 250 600 0 0 0 284.6097 354.5923
                                            A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                            `
                                        }
                                        fill="none"
                                        stroke="silver"
                                        strokeWidth="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div className={'DTRContent'}>
                            <div className={'DTRTitle'}>
                                {'Phase 3 - Yeety Breeding'}
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'Yeety bird breeding goes live! Any holder ' +
                                    'of 2 or more Yeety Birds will be able to breed ' +
                                    'their birds to get new baby Yeety Birds. ' +
                                    'Will include new features that can be acquired ' +
                                    'through mutations, and "genetic" feature ' +
                                    'recombination! Due to how Yeety Birds are coded, ' +
                                    'the possibilities will be practically infinite!'
                                }
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'We will also release a whitepaper detailing ' +
                                    'steps we plan to take in the future, ' +
                                    'as well as updates on our long-term vision.'
                                }
                            </div>
                        </div>
                    </div>
                    {/*<div className={'DTRow'}>*/}
                    {/*    <div className={'Percentage'}>*/}
                    {/*        /!*<div className={'PInner'}>*!/*/}
                    {/*        /!*    {'70%'}*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <svg*/}
                    {/*            version="1.1"*/}
                    {/*            baseProfile="full"*/}
                    {/*            width={`${window.innerWidth*0.18}`}
                                height={`${window.innerWidth*0.18}`}*/}
                    {/*            xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        >*/}
                    {/*            <g*/}
                    {/*                transform={*/}
                    {/*                    `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                <path*/}
                    {/*                    d={*/}
                    {/*                        `*/}
                    {/*                        M ${lwtX} ${lwtY}*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 322.62 236.80736*/}
                    {/*                        A 240 500 0 0 1 500 400*/}
                    {/*                        A 250 500 0 0 1 677.38 236.80736*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 715.3903 354.5923*/}
                    {/*                        A 250 600 0 0 0 500 650*/}
                    {/*                        A 250 600 0 0 0 284.6097 354.5923*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}*/}
                    {/*                        `*/}
                    {/*                    }*/}
                    {/*                    fill="none"*/}
                    {/*                    stroke="silver"*/}
                    {/*                    strokeWidth="10"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*            </g>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className={'DTRContent'}>*/}
                    {/*        <div className={'DTRTitle'}>*/}
                    {/*            {'Phase 6 - P2E'}*/}
                    {/*        </div>*/}
                    {/*        <div className={'DTRDesc'}>*/}
                    {/*            {*/}
                    {/*                'We release a P2E game with exclusively available ' +*/}
                    {/*                'to Yeety Bird holders. Iteration V1 of this game will ' +*/}
                    {/*                'offer PvP, Yeety Bird staking, breeding, and more!'*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'DTRow'}>
                        <div className={'Percentage'}>
                            {/*<div className={'PInner'}>*/}
                            {/*    {'80%'}*/}
                            {/*</div>*/}
                            <svg
                                version="1.1"
                                baseProfile="full"
                                width={`${window.innerWidth*0.18}`}
                                height={`${window.innerWidth*0.18}`}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform={
                                        `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`
                                    }
                                >
                                    <path
                                        d={
                                            `
                                            M ${lwtX} ${lwtY}
                                            A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                            A 240 500 0 0 1 500 400
                                            A 250 500 0 0 1 677.38 236.80736
                                            A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}
                                            A ${radX2} ${radY2} 0 0 0 715.3903 354.5923
                                            A 250 600 0 0 0 500 650
                                            A 250 600 0 0 0 284.6097 354.5923
                                            A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                            `
                                        }
                                        fill="none"
                                        stroke="silver"
                                        strokeWidth="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div className={'DTRContent'}>
                            {/*<div className={'DTRTitle'}>*/}
                            {/*    {'Phase 7 - The Future Awaits'}*/}
                            {/*</div>*/}
                            <div className={'DTRTitle'}>
                                {'Phase 4 - The Future Awaits'}
                            </div>
                            {/*<div className={'DTRDesc'}>*/}
                            {/*    {*/}
                            {/*        'We will release another whitepaper detailing ' +*/}
                            {/*        'the next steps in the P2E game and the Yeetyverse. ' +*/}
                            {/*        'This will include details on virtual real estate, ' +*/}
                            {/*        'yeetynomics, commerce, and tokenomics.'*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <div className={'DTRDesc'}>
                                {
                                    'Wen Yeetyverse? Wen $YEETY? Well, you\'ll ' +
                                    'have to wait and see what we build! Good things ' +
                                    'will also come to the attentive, so pay close ' +
                                    'attention to what we post! There will be hints ;)'
                                }
                            </div>
                            <div className={'DTRDesc'}>
                                {
                                    'Stay YEETY, my frens!! (🐦,🐤)'
                                }
                            </div>
                        </div>
                    </div>
                    {/*<div className={'DTRow'}>*/}
                    {/*    <div className={'Percentage'}>*/}
                    {/*        /!*<div className={'PInner'}>*!/*/}
                    {/*        /!*    {'100%'}*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <svg*/}
                    {/*            version="1.1"*/}
                    {/*            baseProfile="full"*/}
                    {/*            width={`${window.innerWidth*0.18}`}*/}
                    {/*            height={`${window.innerWidth*0.18}`}*/}
                    {/*            xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        >*/}
                    {/*            <g*/}
                    {/*                transform={*/}
                    {/*                    `scale(${(window.innerWidth*0.18)/1000} ${(window.innerWidth*0.18)/1000})`*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                <path*/}
                    {/*                    d={*/}
                    {/*                        `*/}
                    {/*                        M ${lwtX} ${lwtY}*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 322.62 236.80736*/}
                    {/*                        A 240 500 0 0 1 500 400*/}
                    {/*                        A 250 500 0 0 1 677.38 236.80736*/}
                    {/*                        A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 715.3903 354.5923*/}
                    {/*                        A 250 600 0 0 0 500 650*/}
                    {/*                        A 250 600 0 0 0 284.6097 354.5923*/}
                    {/*                        A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}*/}
                    {/*                        `*/}
                    {/*                    }*/}
                    {/*                    fill="none"*/}
                    {/*                    stroke="silver"*/}
                    {/*                    strokeWidth="10"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*            </g>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    <div className={'DTRContent'}>*/}
                    {/*        <div className={'DTRTitle'}>*/}
                    {/*            {'Phase 8 - Into the Yeetyverse'}*/}
                    {/*        </div>*/}
                    {/*        <div className={'DTRDesc'}>*/}
                    {/*            {*/}
                    {/*                'We will release a metaverse game with virtual real ' +*/}
                    {/*                'estate and virtual commerce. This game will ' +*/}
                    {/*                'seriously extend the features found in the V1 ' +*/}
                    {/*                'P2E game, and will allow anyone in the world ' +*/}
                    {/*                'to participate in the Yeetyverse ecosystem. ' +*/}
                    {/*                'How exactly? Stay tuned and stay Yeety to find out!'*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className={'Team'}>
                    <div className={'SectionHeading'}>
                        Team
                    </div>
                    <div className={'TeamRow'}>
                        <div className={'TeamMember'}>
                            <div className={'PFPContainer'}>
                                <img
                                    src={tomislav}
                                    alt={'Tomislav Zabcic-Matic'}
                                />
                            </div>
                            <div className={'TMName'}>
                                Tomislav Žabčić&#8209;Matić
                            </div>
                            <div className={'TMTitle'}>
                                Head Designer and Engineer
                            </div>
                        </div>
                        <div className={'TeamMember'}>
                            <div className={'PFPContainer'}>
                                <img
                                    src={karan}
                                    alt={'Karan Aggarwal'}
                                />
                            </div>
                            <div className={'TMName'}>
                                Karan Aggarwal
                            </div>
                            <div className={'TMTitle'}>
                                Management
                            </div>
                        </div>
                        <div className={'TeamMember'}>
                            <div className={'PFPContainer'}>
                                <img
                                    src={joe}
                                    alt={'Joe Sawicki'}
                                />
                            </div>
                            <div className={'TMName'}>
                                Joe Sawicki
                            </div>
                            <div className={'TMTitle'}>
                                Operations
                            </div>
                        </div>
                    </div>
                    <div className={'TeamRow'}>
                        <div className={'TeamMember'}>
                            <div className={'PFPContainer'}>
                                <img
                                    src={shanmugam}
                                    alt={'Shanmugam Thiagarajan'}
                                />
                            </div>
                            <div className={'TMName'}>
                                Shanmugam Thiagarajan
                            </div>
                            <div className={'TMTitle'}>
                                Marketing/Partnerships
                            </div>
                        </div>
                        <div className={'TeamMember'}>
                            <div className={'PFPContainer'}>
                                <img
                                    src={alberto}
                                    alt={'Alberto Hernandez'}
                                />
                            </div>
                            <div className={'TMName'}>
                                Alberto Hernandez
                            </div>
                            <div className={'TMTitle'}>
                                Engineering
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'FAQ'}>
                    <div className={'SectionHeading'}>
                        FAQ
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'What is the mint price?'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'The mint price will be announced once we ' +
                                'build up the Yeety Flock enough to ensure a ' +
                                'successful launch. We\'re aiming for Yeety Birds ' +
                                'to be affordable so that everyone in the ' +
                                'Yeety Flock has fair access.'
                            }
                        </div>
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'How many Yeety Birds will exist?'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'The Yeetening will bring 10,000 Yeety Birds ' +
                                'into the Yeetyverse, though later milestones will ' +
                                'bring about Yeety Bird breeding, which will accompany ' +
                                'the P2E game we plan to release.'
                            }
                        </div>
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'Is every Yeety Bird unique?'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'Yes, each Yeety Bird has a unique combination of ' +
                                'features! There are over 15 *BILLION* possible ' +
                                'feature combinations, so it is easy to guarantee that ' +
                                'each of the 10,000 Yeety Birds in this collection ' +
                                'is unique. In addition, this means that there\'s ' +
                                'plenty of room for each of the Baby Yeetys to be unique.'
                            }
                        </div>
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'Is there a presale/whitelist?'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'There will be two presale phases. We\'ve reserved ' +
                                '500 Yeety Birds for partnerships and marketing purposes. ' +
                                'These first 500 will mint for free (only pay gas)! More ' +
                                'details on how to get into the free mint ' +
                                'will be released throughout Phase 1! 2,000 more ' +
                                'will be reserved for a presale whitelist. The ' +
                                'remaining 7,500 will be offered in a public sale.'
                            }
                        </div>
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'Wen YEET? (When is Mint Day?)'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'The Yeetening (Mint Day) will also be determined ' +
                                'once we\'ve grown the Yeety Flock enough to ensure ' +
                                'a successful launch. Details will be announced at ' +
                                'some point during Phase 1!'
                            }
                        </div>
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'Is the team doxxed?'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'Yes! The team behind Yeety Birds comes ' +
                                'from backgrounds in the tech and finance ' +
                                'industries and bring their talents to this ' +
                                'project via TeraChain Blockchain Studio. Learn ' +
                                'more at www.terachain.co!'
                            }
                        </div>
                    </div>
                    <div className={'FAQItem'}>
                        <div className={'Question'}>
                            {
                                'Which nature conservancies will be candidates ' +
                                'for the 10% share allocated to charity?'
                            }
                        </div>
                        <div className={'Answer'}>
                            {
                                'We will release the candidates towards the end of ' +
                                'Phase 1. Between this time, we will also ' +
                                'accept suggestions via our Discord channel.'
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    height: isMobile(navigator) ? '60px' : '80px',
                    minHeight: isMobile(navigator) ? '60px' : '80px',
                }}
            />
            <div className={'Footer'}>
                <div className={'LogoContainer'}>
                    <img
                        src={yeetyBirdsLogo}
                        alt={'Yeety Birds NFT'}
                    />
                </div>
                <div className={'PoweredBy'}>
                    Powered By
                </div>
                <div className={'TeraChain'}>
                    <a
                        href={'https://www.terachain.co'}
                        rel={'nofollow noreferrer'}
                        target={'_blank'}
                    >
                        <div className={'Name'}>
                            <div className={'NameInner'}>
                                TERACHAIN
                            </div>
                        </div>
                        <div className={'Subtitle'}>
                            <div className={'SubtitleInner'}>
                                Blockchain Studio
                            </div>
                        </div>
                    </a>
                </div>
                <div className={'Copyright'}>
                    &copy;&nbsp;
                    {
                        '2022 Yeety Birds. All rights reserved.'
                    }
                </div>
                <div className={'Socials'}>
                    <div className={'SocialIcon'}>
                        <a
                            href={'https://twitter.com/YeetyFlock'}
                            rel={'nofollow noreferrer'}
                            target={'_blank'}
                        >
                            <img
                                src={twitterIcon}
                                alt={'Yeety Birds Twitter'}
                                title={'Yeety Birds Twitter - @YeetyFlock'}
                            />
                        </a>
                    </div>
                    <div className={'SocialIcon'}>
                        <a
                            href={'https://www.instagram.com/yeetybirds/'}
                            rel={'nofollow noreferrer'}
                            target={'_blank'}
                        >
                            <img
                                src={instagramIcon}
                                alt={'Yeety Birds Instagram'}
                                title={'Yeety Birds Instagram - @yeetybirds'}
                            />
                        </a>
                    </div>
                    <div className={'SocialIcon'}>
                        <a
                            href={'https://discord.gg/RkPG55KsMX'}
                            rel={'nofollow noreferrer'}
                            target={'_blank'}
                        >
                            <img
                                src={discordIcon}
                                alt={'Yeety Birds Discord'}
                                title={'Discord coming soon!'}
                            />
                        </a>
                    </div>
                    <div className={'SocialIcon'}>
                        <img
                            src={openSeaIcon}
                            alt={'Yeety Birds OpenSea'}
                            title={'Coming soon to OpenSea!'}
                        />
                    </div>
                </div>
            </div>
            <div
                className={
                    'Popup ' + (this.state.spStaging ? 'Show' : 'Hide')
                }
            >
                <div
                    className={
                        'PopupContent ' + (this.state.showPopup ? 'Show' : 'Hide')
                    }
                >
                    <div className={'PopupInner'}>
                        <div className={'Heading'}>
                            Stay YEETy With Us
                        </div>
                        <div className={'Subheading'}>

                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
