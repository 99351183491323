/*
 * Description: Home page file containing a list of faucets and various faucet details
 * Author: Tomislav Žabčić-Matić
 */

// Imports:
import * as React from 'react';

// import './HomeDesktop.css';
// import './HomeMobile.css';

// Global Constants:

// Component:
export default class Test extends React.PureComponent {
    state = {
        leftWingTipX: 100,
        leftWingTipY: 550,
        rightWingTipX: 900,
        rightWingTipY: 550,
        timeStep: 0,
    }

    componentDidMount() {
        this.flap();
    }

    flap = () => {
        this.setState((state) => {
            const { timeStep } = state;

            const newTimeStep = (timeStep + 1)%(314);

            const [newLWTX, newLWTY, newRWTX, newRWTY] = [
                100 - 75*((-Math.cos(timeStep/10) + 1)/2),
                550 - 75*((-Math.cos(timeStep/10) + 1)/2),
                900 + 75*((-Math.cos(timeStep/10) + 1)/2),
                550 - 75*((-Math.cos(timeStep/10) + 1)/2),
            ];

            return ({
                leftWingTipX: newLWTX,
                leftWingTipY: newLWTY,
                rightWingTipX: newRWTX,
                rightWingTipY: newRWTY,
                timeStep: newTimeStep,
            });
        }, () => {
            setTimeout(async () => {
                this.flap();
            }, 15);
        });
    }

    render() {
        const lwtX = this.state.leftWingTipX;
        const lwtY = this.state.leftWingTipY;
        const rwtX = this.state.rightWingTipX;
        const rwtY = this.state.rightWingTipY;

        const radX1 = Math.min(1000000, (1 - 3*Math.log((lwtY - 236.80736)/(550 - 236.80736)))*240);
        const radX2 = Math.min(1000000, (1 - 3.5*Math.log((rwtY - 354.5923)/(550 - 354.5923)))*250);
        const radY1 = Math.min(1000000, (1 - 3*Math.log((lwtY - 236.80736)/(550 - 236.80736)))*500);
        const radY2 = Math.min(1000000, (1 - 3*Math.log((rwtY - 354.5923)/(550 - 354.5923)))*600);

        const wtMvt = 100 - lwtX;
        const wtMvtRatio = wtMvt/75;
        const swordAngle = 45 + 10*wtMvtRatio;

        return (<div
            className={'Test'}
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
                backgroundColor: 'black',
            }}
        >
            <div
                style={{
                    width: 'min(60vw, 60vh)',
                    height: 'min(60vw, 60vh)',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                <svg
                    version="1.1"
                    baseProfile="full"
                    width="100%" height="100%"
                    viewBox={'0 0 1000 1000'}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 9,
                    }}
                >
                    <defs>
                        <pattern
                            id="swordHiltPattern"
                            width="12" height="18"
                            patternUnits="userSpaceOnUse"
                            patternTransform="translate(0 -9)"
                        >
                            <rect
                                x="0" y="0"
                                width="12" height="18"
                                fill="#222222"
                            />
                            <path
                                d={
                                    `
                                M 6 3
                                L 2.5 9
                                L 6 15
                                L 9.5 9
                                L 6 3
                                `
                                }
                                fill="#CC0000"
                            />
                        </pattern>
                        <linearGradient id="swordHiltShadow" x1="50%" y1="0%" x2="50%" y2="100%">
                            <stop offset="25%" stopColor="black" stopOpacity="0"/>
                            <stop offset="100%" stopColor="black" stopOpacity="0.4"/>
                        </linearGradient>
                    </defs>
                    <g transform={`translate(${125 - wtMvt*0.75} ${480 - wtMvt*0.75})`}>
                        <g transform={`rotate(${swordAngle})`}>
                            <path
                                d={
                                    `
                                M 50 -9
                                L -50 -9
                                A 3 9 0 0 0 -50 9
                                L 50 9
                                L 50 -9
                                `
                                }
                                fill="url(#swordHiltPattern)"
                            />
                            <path
                                d={
                                    `
                                M 50 -9
                                L -50 -9
                                A 3 9 0 0 0 -50 9
                                L 50 9
                                L 50 -9
                                `
                                }
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill="url(#swordHiltShadow)"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    version="1.1"
                    baseProfile="full"
                    width="100%" height="100%"
                    viewBox={'0 0 1000 1000'}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 20,
                    }}
                >
                    <g transform={`translate(${125 - wtMvt*0.75} ${480 - wtMvt*0.75})`}>
                        <g transform={`rotate(${swordAngle})`}>
                            <g transform="scale(0.75 1.05) translate(67 0)">
                                <g transform="rotate(45)">
                                    <path
                                        d={
                                            `
                                        M -26 0
                                        A 26 26 0 0 0 26 0
                                        A 26 26 0 0 0 -26 0
                                        M 4.15 -10.187
                                        L 9.675 -19.758
                                        A 22 22 0 0 0 -9.675 -19.758
                                        L -4.15 -10.187
                                        A 11 11 0 0 1 4.15 -10.187
                                        M 4.15 10.187
                                        L 9.675 19.758
                                        A 22 22 0 0 1 -9.675 19.758
                                        L -4.15 10.187
                                        A 11 11 0 0 0 4.15 10.187
                                        M 10.863 -1.732
                                        L 21.932 -1.732
                                        A 22 22 0 0 0 12.273 -18.258
                                        L 6.748 -8.687
                                        A 11 11 0 0 1 10.863 -1.732
                                        M 10.863 1.732
                                        L 21.932 1.732
                                        A 22 22 0 0 1 12.273 18.258
                                        L 6.748 8.687
                                        A 11 11 0 0 0 10.863 1.732
                                        M -10.863 -1.732
                                        L -21.932 -1.732
                                        A 22 22 0 0 1 -12.273 -18.258
                                        L -6.748 -8.687
                                        A 11 11 0 0 0 -10.863 -1.732
                                        M -10.863 1.732
                                        L -21.932 1.732
                                        A 22 22 0 0 0 -12.273 18.258
                                        L -6.748 8.687
                                        A 11 11 0 0 1 -10.863 1.732
                                        `
                                        }
                                        stroke="black"
                                        strokeWidth="0.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="#955124"
                                        fillRule="evenodd"
                                        transform="translate(-1 1)"
                                    />
                                    <path
                                        d={
                                            `
                                        M 10.675 -20.758
                                        L 8.675 -18.758
                                        M 22.932 0.732
                                        L 20.932 2.732
                                        M 5.15 9.187
                                        L 3.15 11.187
                                        M -5.748 7.687
                                        L -7.748 9.687
                                        M -9.863 0.732
                                        L -11.863 2.732
                                        M -9.863 -2.732
                                        L -11.863 -0.732
                                        M -11.273 -19.258
                                        L -13.273 -17.258
                                        `
                                        }
                                        stroke="black"
                                        strokeWidth="0.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="none"
                                    />
                                    <path
                                        d={
                                            `
                                        M -26 0
                                        A 26 26 0 0 0 26 0
                                        A 26 26 0 0 0 -26 0
                                        M 4.15 -10.187
                                        L 9.675 -19.758
                                        A 22 22 0 0 0 -9.675 -19.758
                                        L -4.15 -10.187
                                        A 11 11 0 0 1 4.15 -10.187
                                        M 4.15 10.187
                                        L 9.675 19.758
                                        A 22 22 0 0 1 -9.675 19.758
                                        L -4.15 10.187
                                        A 11 11 0 0 0 4.15 10.187
                                        M 10.863 -1.732
                                        L 21.932 -1.732
                                        A 22 22 0 0 0 12.273 -18.258
                                        L 6.748 -8.687
                                        A 11 11 0 0 1 10.863 -1.732
                                        M 10.863 1.732
                                        L 21.932 1.732
                                        A 22 22 0 0 1 12.273 18.258
                                        L 6.748 8.687
                                        A 11 11 0 0 0 10.863 1.732
                                        M -10.863 -1.732
                                        L -21.932 -1.732
                                        A 22 22 0 0 1 -12.273 -18.258
                                        L -6.748 -8.687
                                        A 11 11 0 0 0 -10.863 -1.732
                                        M -10.863 1.732
                                        L -21.932 1.732
                                        A 22 22 0 0 0 -12.273 18.258
                                        L -6.748 8.687
                                        A 11 11 0 0 1 -10.863 1.732
                                        `
                                        }
                                        stroke="black"
                                        strokeWidth="0.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="#B87333"
                                        fillRule="evenodd"
                                        transform="translate(1 -1)"
                                    />
                                    <path
                                        d={
                                            `
                                        M -17.675 -19.09
                                        L -19.69 -17.075
                                        A 26 26 0 0 0 17.075 19.69
                                        L 19.09 17.675
                                        A 26 26 0 0 1 -17.675 -19.09
                                        `
                                        }
                                        fill="#955124"
                                        stroke="black"
                                        strokeWidth="0.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </g>
                            <path
                                d={
                                    `
                                M 50 9
                                A 3000 3000 0 0 0 405 -15
                                A 45 45 0 0 0 435 -35
                                A 3000 3000 0 0 1 50 -9
                                A 1 9 0 0 0 50 9
                                `
                                }
                                fill="#666666"
                            />
                            <path
                                d={
                                    `
                                M 50 9
                                A 3000 3000 0 0 0 405 -15
                                A 45 45 0 0 0 435 -35
                                A 3000 3000 0 0 1 428.5 -34.15
                                A 45 45 0 0 1 400 -20
                                A 3000 3000 0 0 1 49.05 3
                                A 1 9 0 0 0 50 9
                                `
                                }
                                fill="#DDDDDD"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    version="1.1"
                    baseProfile="full"
                    width="100%" height="100%"
                    viewBox={'0 0 1000 1000'}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 10,
                    }}
                >
                    <g
                        transform={
                            // 'scale(-1 1) translate(-1000 0)'
                            ''
                        }
                    >
                        <svg
                            version="1.1"
                            baseProfile="full"
                            width="1000" height="1000"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <filter id="blur5">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                                </filter>
                                <clipPath id="bodyClip">
                                    <path
                                        d={
                                            `
                                        M ${lwtX} ${lwtY}
                                        A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                        A 240 500 0 0 1 500 400
                                        A 250 500 0 0 1 677.38 236.80736
                                        A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}
                                        A ${radX2} ${radY2} 0 0 0 715.3903 354.5923
                                        A 250 600 0 0 0 500 650
                                        A 250 600 0 0 0 284.6097 354.5923
                                        A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                        `
                                        }
                                        fill="none"
                                    />
                                </clipPath>
                                <clipPath id="leftWingClip">
                                    <path
                                        d={
                                            `
                                        M ${lwtX} ${lwtY}
                                        A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                        A 240 500 0 0 1 500.005 400
                                        L 500.005 650
                                        A 250 600 0 0 0 284.6097 354.5923
                                        A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                        `
                                        }
                                        fill="none"
                                    />
                                </clipPath>
                                <clipPath id="rightWingClip">
                                    <path
                                        d={
                                            `
                                        M ${rwtX} ${rwtY}
                                        A ${radX1} ${radY1} 0 0 0 677.38 236.80736
                                        A 240 500 0 0 0 499.995 400
                                        L 499.995 650
                                        A 250 600 0 0 1 715.3903 354.5923
                                        A ${radX2} ${radY2} 0 0 1 ${rwtX} ${rwtY}
                                        `
                                        }
                                        fill="none"
                                    />
                                </clipPath>
                                <radialGradient id="wingGrad1">
                                    <stop offset="50%" stopColor="#C03E2E" />
                                    <stop offset="100%" stopColor="black" stopOpacity="0"/>
                                </radialGradient>
                                <radialGradient id="wingGrad2">
                                    <stop offset={`${65 + 12.5*(100 - lwtX)/50}%`} stopColor="#C03E2E" />
                                    <stop offset="100%" stopColor="black" stopOpacity="0"/>
                                </radialGradient>
                            </defs>
                            <g
                                clipPath="url(#bodyClip)"
                            >
                                <rect
                                    x="0" y="0"
                                    width="1000" height="1000"
                                    fill="#F77B5C"
                                />
                            </g>
                            <path
                                d={
                                    `
                                M ${lwtX - 20} ${lwtY}
                                A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                A 240 500 0 0 1 500 400
                                A 240 500 0 0 1 322.62 1236.80736
                                A ${radX1} ${radY1} 0 0 1 ${lwtX - 20} ${lwtY}
                                `
                                }
                                fill={'url(#wingGrad1)'}
                                clipPath={'url(#leftWingClip)'}
                            />
                            <path
                                d={
                                    `
                                M ${rwtX + 20} ${rwtY}
                                A ${radX1} ${radY1} 0 0 0 677.38 236.80736
                                A 240 500 0 0 0 500 400
                                A 240 500 0 0 0 677.38 1236.80736
                                A ${radX1} ${radY1} 0 0 0 ${rwtX + 20} ${rwtY}
                                `
                                }
                                fill={'url(#wingGrad1)'}
                                clipPath={'url(#rightWingClip)'}
                            />
                            <path
                                d={
                                    `
                                    M ${lwtX} ${lwtY}
                                    A ${radX1} ${radY1} 0 0 1 322.62 236.80736
                                    A 240 500 0 0 1 500 400
                                    A 250 500 0 0 1 677.38 236.80736
                                    A ${radX1} ${radY1} 0 0 1 ${rwtX} ${rwtY}
                                    A ${radX2} ${radY2} 0 0 0 715.3903 354.5923
                                    A 250 600 0 0 0 500 650
                                    A 250 600 0 0 0 284.6097 354.5923
                                    A ${radX2} ${radY2} 0 0 0 ${lwtX} ${lwtY}
                                    `
                                }
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill="none"
                            />
                        </svg>
                        <svg
                            version="1.1"
                            baseProfile="full"
                            width="1000" height="1000"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g transform="translate(0 30)">
                                <path
                                    d="
                                M -100 -100
                                L -100 1100
                                L 1100 1100
                                L 1100 -100
                                L -100 -100
                                L -100 1100
                                L 1100 1100
                                L 1100 -100
                                L -100 -100

                                M 130 700
                                L 160 680
                                L 220 790
                                L 280 680
                                L 310 700
                                L 230 805
                                L 240 920
                                L 200 920
                                L 210 805
                                L 130 700

                                M 330 700
                                L 480 680
                                L 490 715
                                L 350 720
                                L 360 790
                                L 450 785
                                L 450 815
                                L 360 810
                                L 350 880
                                L 490 885
                                L 480 920
                                L 330 900
                                L 330 700

                                M 510 700
                                L 660 680
                                L 670 715
                                L 530 720
                                L 540 790
                                L 630 785
                                L 630 815
                                L 540 810
                                L 530 880
                                L 670 885
                                L 660 920
                                L 510 900
                                L 510 700

                                M 690 680
                                L 870 680
                                L 865 720
                                L 790 710
                                L 800 920
                                L 760 920
                                L 770 710
                                L 695 720
                                L 690 680
                                "
                                    fill="#991CF0"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fillRule="evenodd"
                                />
                            </g>
                        </svg>
                        <svg
                            version="1.1"
                            baseProfile="full"
                            width="1000" height="1000"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <linearGradient id="capeGrad" x1="50%" y1="0%" x2="50%" y2="100%">
                                    <stop offset="0%" stopColor={'black'} stopOpacity={'0'} />
                                    <stop offset="100%" stopColor={'black'} stopOpacity={'0.5'} />
                                </linearGradient>
                            </defs>
                            <path
                                d="M 400 422
                            A 200 200 0 0 0 355 550
                            A 100 100 0 0 1 310 630
                            A 60 60 0 0 0 280 680
                            A 80 80 0 0 1 380 680
                            A 74 74 0 0 0 460 680
                            A 66 66 0 0 1 540 680
                            A 60 60 0 0 0 620 680
                            A 60 60 0 0 1 640 670
                            A 200 200 0 0 0 610 500
                            A 100 100 0 0 1 600 422
                            A 250 600 0 0 0 500 650
                            A 250 600 0 0 0 400 422
                            "
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill="#39773E"
                            />
                            <path
                                d="M 400 422
                            A 200 200 0 0 0 355 550
                            A 100 100 0 0 1 310 630
                            A 60 60 0 0 0 280 680
                            A 80 80 0 0 1 380 680
                            A 74 74 0 0 0 460 680
                            A 66 66 0 0 1 540 680
                            A 60 60 0 0 0 620 680
                            A 60 60 0 0 1 640 670
                            A 200 200 0 0 0 610 500
                            A 100 100 0 0 1 600 422
                            A 250 600 0 0 0 500 650
                            A 250 600 0 0 0 400 422
                            "
                                fill="url(#capeGrad)"
                            />
                            <path
                                d="M 380 680
                            A 300 300 0 0 0 420 550
                            M 460 680
                            A 200 200 0 0 0 475 620
                            M 540 680
                            A 250 250 0 0 0 560 575
                            "
                                stroke="#214424"
                                strokeWidth="2"
                                strokeLinecap="round"
                                fill="none"
                            />
                            <path
                                d="M 380 680
                            A 74 74 0 0 0 460 680
                            A 66 66 0 0 1 540 680
                            A 60 60 0 0 0 620 680
                            "
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill="none"
                            />
                            <path
                                d="M 390 420
                            A 20 12 53 0 0 402 436
                            A 10 6 -45 0 0 410 430
                            A 20 10 53 0 0 398 414
                            A 10 6 -45 0 0 390 420
                            A 20 12 53 0 0 402 436
                            "
                                stroke="#444444"
                                strokeWidth="2"
                                fill="silver"
                            />
                            <path
                                d="M 610 420
                            A 20 12 -53 0 1 598 436
                            A 10 6 45 0 1 590 430
                            A 20 10 -53 0 1 602 414
                            A 10 6 45 0 1 610 420
                            A 20 12 -53 0 1 598 436
                            "
                                stroke="#444444"
                                strokeWidth="2"
                                fill="silver"
                            />
                        </svg>
                        {/* Insert sword here... */}
                        <svg
                            version="1.1"
                            baseProfile="full"
                            width="1000" height="1000"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <circle
                                    cx="475"
                                    cy="475"
                                    r="15"
                                    fill="white"
                                    stroke="black"
                                    strokeWidth="1"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <circle
                                    cx="470"
                                    cy="470"
                                    r="8"
                                    fill="black"
                                />
                                <circle
                                    cx="525"
                                    cy="475"
                                    r="15"
                                    fill="white"
                                    stroke="black"
                                    strokeWidth="1"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <circle
                                    cx="530"
                                    cy="480"
                                    r="8"
                                    fill="black"
                                />
                            </g>
                        </svg>
                        <svg
                            version="1.1"
                            baseProfile="full"
                            width="1000" height="1000"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <linearGradient id="beakShadow" x1="50%" y1="0%" x2="50%" y2="100%" gradientTransform="rotate(-10)">
                                    <stop offset="65%" stopColor="black" stopOpacity="0" />
                                    <stop offset="100%" stopColor="black" stopOpacity="0.4" />
                                </linearGradient>
                            </defs>
                            <g>
                                <path
                                    d="M 500 500
                                L 650 525
                                L 500 550
                                L 485 525
                                L 500 500
                                "
                                    fill="#7DF8C6"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M 500 500
                                L 650 525
                                L 500 550
                                L 485 525
                                L 500 500
                                "
                                    fill="url(#beakShadow)"
                                />
                                <path
                                    d="M 530 505
                                L 524 504
                                A 10 10 0 1 0 524 516
                                A 4 4 0 0 1 530 515
                                A 15 15 0 1 1 530 505
                                "
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill="silver"
                                />
                            </g>
                        </svg>
                    </g>
                </svg>
                <svg
                    version="1.1"
                    baseProfile="full"
                    width="100%" height="100%"
                    viewBox={'0 0 1000 1000'}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 0,
                    }}
                >
                    <g
                        transform={
                            // 'scale(-1 1) translate(-1000 0)'
                            ''
                        }
                    >
                        <svg
                            version="1.1"
                            baseProfile="full"
                            width="1000" height="1000"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <filter id="blur25">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="25" />
                                </filter>
                            </defs>
                            <g
                                filter="url(#blur25)"
                            >
                                <rect
                                    width="1200"
                                    height="1200"
                                    x="-100"
                                    y="-100"
                                    fill="#C39ACB"
                                />
                                <g>
                                    <circle
                                        cx="0"
                                        cy="0"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="333.33"
                                        cy="0"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="666.67"
                                        cy="0"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="1000"
                                        cy="0"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                                <g>
                                    <circle
                                        cx="166.67"
                                        cy="166.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="500"
                                        cy="166.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="833.33"
                                        cy="166.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                                <g>
                                    <circle
                                        cx="0"
                                        cy="333.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="333.33"
                                        cy="333.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="666.67"
                                        cy="333.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="1000"
                                        cy="333.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                                <g>
                                    <circle
                                        cx="166.67"
                                        cy="500"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="500"
                                        cy="500"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="833.33"
                                        cy="500"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                                <g>
                                    <circle
                                        cx="0"
                                        cy="666.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="333.33"
                                        cy="666.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="666.67"
                                        cy="666.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="1000"
                                        cy="666.67"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                                <g>
                                    <circle
                                        cx="166.67"
                                        cy="833.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="500"
                                        cy="833.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="833.33"
                                        cy="833.33"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                                <g>
                                    <circle
                                        cx="0"
                                        cy="1000"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="333.33"
                                        cy="1000"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="666.67"
                                        cy="1000"
                                        r="50"
                                        fill="#A42823"
                                    />
                                    <circle
                                        cx="1000"
                                        cy="1000"
                                        r="50"
                                        fill="#A42823"
                                    />
                                </g>
                            </g>
                        </svg>
                    </g>
                </svg>
            </div>
        </div>);
    }
}
